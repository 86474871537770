import React, {FC} from "react";
import localStyles from "./Dates.module.scss";
import styles from "../edit-container/EditContainer.module.scss";
import {save, reset, getAdjustmentPreview} from "../../store/addNightSlice";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {FinancesColumn, Alerts} from "../index";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import {amountFinancesContent} from "../index";
import {CircularProgress} from "@material-ui/core";

import {dayjs, toDateString, toDateTimeString} from "../../utils";
import {Space, DatePicker, message} from "antd";
import {Dayjs} from "dayjs";
import {CalendarDayInfo, getCalendarDayInfo} from "../../utils/Availability/Availability";
import {COMMON_DATE_FORMAT} from "../../constants";
import {AvailabilityAttributesType, DataType} from "@common/typing";

interface DatesProps {}

export const Dates: FC<DatesProps> = () => {
  const dispatch = useAppDispatch();

  const finances = useSelector((state: RootState) => state.addNight.finances);
  const reservation = useSelector((state: RootState) => state.fetcher.reservation);
  const firstNight = useSelector((state: RootState) => state.addNight?.firstNight);
  const lastNight = useSelector((state: RootState) => state.addNight?.lastNight);
  const saving = useSelector((state: RootState) => state.addNight.persist.loading);
  const previewId = useSelector((state: RootState) => state.addNight.preview.id);
  const previewLoading = useSelector((state: RootState) => state.addNight.preview.loading);
  const preAvailabilityLimit = useSelector((state: RootState) => state.addNight?.availability?.preLimit);
  const postAvailabilityLimit = useSelector((state: RootState) => state.addNight?.availability?.postLimit);
  const preAvailabilities = useSelector((state: RootState) => state.addNight?.availability?.preAvailabilities);
  const postAvailabilities = useSelector((state: RootState) => state.addNight?.availability?.postAvailabilities);
  const preAvailabilitiesReverted = preAvailabilities?.slice().reverse();
  const calendarDaysArray: CalendarDayInfo[] = [];

  type Dayjs = typeof Dayjs;

  function validateSave() {
    return (
      (firstNight !== toDateString(reservation?.data?.attributes?.first_night) ||
        lastNight !== toDateString(reservation?.data?.attributes?.last_night)) &&
      !previewLoading
    );
  }

  async function handleSave() {
    dispatch(save());
  }

  function handleReset() {
    dispatch(reset());
  }

  return (
    <div>
      <Alerts />

      <div className={styles.body}>
        <div className={styles.containerItem}>
          <div className={styles.title}>Edit Dates</div>
          <strong>Note: </strong>Manual overrides to fees will need to be reapplied.
          {/* DatePickers Section */}
          <div className={localStyles.datesContainer}>
            <div className={localStyles.dates}>
              <span className={localStyles.datesTitle}>First Night</span>
              <Space>
                <DatePicker
                  allowClear={false}
                  //This property allow you to determine how you want to render each "cell" (day) of the calendar
                  cellRender={(current, info) => {
                    //Types that are different of "date" or "week" do not support custom rendering so we do an early return with the originNode
                    if (info.type !== "date") return info.originNode;

                    const style = {
                      background: "",
                      color: "",
                    };

                    //Iterates over the preAvailabilitiesReverted array and get the turnover for each availability.
                    preAvailabilitiesReverted.map((availability: DataType<AvailabilityAttributesType>, index: number) => {
                      const currentDate = current.format(COMMON_DATE_FORMAT);
                      const nextAvailability = preAvailabilitiesReverted[index + 1];
                      const availabilityType = "preAvailability";

                      if (currentDate === availability?.attributes?.date) {
                        const calendarDayInfo = getCalendarDayInfo(availabilityType, availability?.attributes, nextAvailability?.attributes);
                        if (calendarDaysArray.length < preAvailabilitiesReverted.length) calendarDaysArray.push(calendarDayInfo);
                        style.background = calendarDayInfo?.background;
                        style.color = calendarDayInfo?.color;
                      }
                    });

                    //Return each day with the custom style to be displayed in the calendar
                    return (
                      <div className="ant-picker-cell-inner" style={style}>
                        {current.date()}
                      </div>
                    );
                  }}
                  data-testid="checkin"
                  defaultValue={dayjs(toDateString(firstNight))}
                  disabled={previewLoading || isNil(finances?.original)}
                  disabledDate={(date) =>
                    !date ||
                    date.isBefore(toDateTimeString(preAvailabilityLimit)) ||
                    date.isAfter(toDateTimeString(reservation?.data?.attributes?.first_night))
                  }
                  onChange={(date: Dayjs | null) => {
                    const dayFilteredFiltered = calendarDaysArray.filter((calendarDay) => calendarDay?.date === date.format(COMMON_DATE_FORMAT));
                    const isAnAvailableDay = isEmpty(dayFilteredFiltered) || dayFilteredFiltered[0]?.isAnAvailableDay;
                    //If the day isn't available we display an error message
                    if (!isAnAvailableDay) {
                      return message.error("You must select a valid date. Check if a turnover is not blocking the date selected!", 5);
                    }
                    dispatch(getAdjustmentPreview({firstNight: toDateString(date)}));
                  }}
                  showToday={false}
                  size="large"
                  value={dayjs(toDateString(firstNight))}
                />
              </Space>
            </div>
            <div className={localStyles.dates}>
              <span className={localStyles.datesTitle}>Last Night</span>
              <Space>
                <DatePicker
                  allowClear={false}
                  //This property allow you to determine how you want to render each "cell" (day) of the calendar
                  cellRender={(current, info) => {
                    //Types that are different of "date" or "week" do not support custom rendering so we do an early return with the originNode
                    if (info.type !== "date") return info.originNode;

                    const style: CalendarDayInfo = {
                      background: "",
                      color: "",
                    };

                    //Iterates over the postAvailabilities array and get the turnover for each availability.
                    postAvailabilities?.map((availability: DataType<AvailabilityAttributesType>, index: number) => {
                      const currentDate = current.format(COMMON_DATE_FORMAT);
                      const nextAvailability = postAvailabilities[index + 1];
                      const availabilityType: string = "postAvailability";

                      if (currentDate === availability?.attributes?.date) {
                        const calendarDayInfo = getCalendarDayInfo(availabilityType, availability?.attributes, nextAvailability?.attributes);
                        if (calendarDaysArray.length < postAvailabilities.length) calendarDaysArray.push(calendarDayInfo);
                        style.background = calendarDayInfo?.background;
                        style.color = calendarDayInfo?.color;
                      }
                    });

                    //Return each day with the custom style to be displayed in the calendar
                    return (
                      <div className="ant-picker-cell-inner" style={style}>
                        {current.date()}
                      </div>
                    );
                  }}
                  data-testid="checkout"
                  defaultValue={dayjs(toDateString(lastNight))}
                  disabled={previewLoading || isNil(finances?.original)}
                  disabledDate={(date) =>
                    !date || date.isBefore(toDateTimeString(lastNight)) || date.isAfter(toDateTimeString(postAvailabilityLimit))
                  }
                  onChange={(date: Dayjs | null) => {
                    const dayFilteredFiltered = calendarDaysArray.filter((calendarDay) => calendarDay?.date === date.format(COMMON_DATE_FORMAT));
                    console.log(dayFilteredFiltered);
                    const isAnAvailableDay = isEmpty(dayFilteredFiltered) || dayFilteredFiltered[0]?.isAnAvailableDay;
                    //If the day isn't available we display an error message
                    if (!isAnAvailableDay) {
                      return message.error("You must select a valid date. Check if a turnover is not blocking the date selected!", 5);
                    }
                    dispatch(getAdjustmentPreview({lastNight: toDateString(date)}));
                  }}
                  showToday={false}
                  size="large"
                  value={dayjs(toDateString(lastNight))}
                />
              </Space>
            </div>
            {previewLoading ? (
              <div className={localStyles.fetchingLoadingProgress}>
                <CircularProgress color="inherit" size={25} />
                <span className={localStyles.loadingProgressText}>Fetching adjustment preview...</span>
              </div>
            ) : null}
          </div>
          {/* Turnover Section */}
          <div className={localStyles.turnoverExplanationBox}>
            <div className={localStyles.turnoverContainer}>
              <span className={localStyles.turnoverZero}></span>
              <p className={localStyles.turnoverExplanationText}>Guest can neither check in nor check out.</p>
            </div>
            <div className={localStyles.turnoverContainer}>
              <span className={localStyles.turnoverTwo}></span>
              <p className={localStyles.turnoverExplanationText}>Guest can only check out.</p>
            </div>
            <div className={localStyles.turnoverContainer}>
              <span className={localStyles.turnoverThree}></span>
              <p className={localStyles.turnoverExplanationText}>Guest can only check in.</p>
            </div>
          </div>
          {/* Finances Section */}
          <div className={localStyles.financesTableTwoColumns}>
            <FinancesColumn
              rightMarginContent={true}
              header={"Before"}
              wideBox={false}
              rows={[
                {title: "RENT", content: amountFinancesContent({amount: finances?.original?.rent ?? "n/a"})},
                {title: "FEES", content: amountFinancesContent({amount: finances?.original?.fees ?? "n/a"})},
                {title: "TAXES", content: amountFinancesContent({amount: finances?.original?.taxes ?? "n/a"})},
                {title: "TOTAL", content: amountFinancesContent({amount: finances?.original?.total ?? "n/a"})},
              ]}
            />

            <FinancesColumn
              showTitles={false}
              leftMarginContent={true}
              header={"After"}
              rows={["RENT", "FEES", "TAXES", "TOTAL"].map((title) => ({
                title,
                content: amountFinancesContent(
                  {amount: !isNil(finances?.preview?.[title.toLocaleLowerCase()]) ? finances?.preview?.[title.toLocaleLowerCase()] : "n/a"},
                  {
                    amount: !isNil(finances?.preview?.[title.toLocaleLowerCase()])
                      ? finances?.preview?.[title.toLocaleLowerCase()] - finances?.original?.[title.toLocaleLowerCase()]
                      : "n/a",
                    wrapper: "(+/-)",
                  }
                ),
              }))}
            />
          </div>
        </div>
      </div>

      {/* Buttons Section */}
      <div className={localStyles.saveDiv}>
        <button
          className={validateSave() && !saving ? localStyles.button : localStyles.buttonDisabled}
          onClick={handleSave}
          disabled={saving || !previewId}
        >
          Save
        </button>
        {validateSave() && !saving ? (
          <span onClick={handleReset} className={localStyles.resetButton}>
            Reset
          </span>
        ) : null}
        {saving ? (
          <div className={localStyles.loadingProgress}>
            <CircularProgress color="inherit" size={25} />
            <span className={localStyles.loadingProgressText}>Saving...</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};
