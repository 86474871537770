import React, {useEffect} from "react";
import {useParams, Outlet} from "react-router-dom";
import {useAppDispatch} from "../../store/store";
import {AddNightHeader} from "../index";
import styles from "./EditContainer.module.scss";
import {fetchAll} from "../../store/fetcherSlice";
import {RootState} from "../../store/store";
import {Image} from "@vacasa/react-components-lib";
import {useSelector} from "react-redux";

export const EditContainer = () => {
  const dispatch = useAppDispatch();
  const {id} = useParams<{id: string}>();
  const isLoading = useSelector((state: RootState) => state.fetcher.isLoading);

  useEffect(() => {
    dispatch(fetchAll(id));
  }, []);

  if (isLoading) {
    return (
      <div className={styles.waitSpinner}>
        <Image.Spinner style={{display: "flex", margin: "auto"}} width={70} />
      </div>
    );
  }

  return (
    <>
      {/** HEADER **/}
      <AddNightHeader />

      {/** CONTAINER **/}
      <div className={styles.container}>
        {/** LEFT SIDE **/}
        <div>
          <Outlet />
        </div>

        {/** RIGHT SIDE **/}
        <div className="right" />
      </div>
    </>
  );
};
