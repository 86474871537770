import {AvailabilityAttributesType} from "@common/typing";
import {TURNOVERS} from "../../constants";

export type CalendarDayInfo = {
    background: string;
    color: string;
    isAnAvailableDay?: boolean;
    date?: string;
};

/**
 * Returns the styles to be added in the calendar for each day based on the current and the next availability turnover
 * @param availabilityType string
 * @param availability AvailabilityAttributesType
 * @param nextAvailability AvailabilityAttributesType
 * @returns CalendarDayInfo object
 */
export function getCalendarDayInfo(
    availabilityType: string,
    availability: AvailabilityAttributesType,
    nextAvailability: AvailabilityAttributesType
): CalendarDayInfo {
    const turnover = availability?.turnover;
    const nextTurnover = nextAvailability?.turnover;
    const calendarDayInfoObject: CalendarDayInfo = {
        background: "",
        color: "",
        isAnAvailableDay: true,
        date: "",
    };

    if (
        (turnover === TURNOVERS.ONLY_CHECKIN || turnover === TURNOVERS.ALLOW_CHECKIN_AND_CHECKOUT) &&
        (nextTurnover === TURNOVERS.ONLY_CHECKIN || nextTurnover === TURNOVERS.NO_CHECKIN_NO_CHECKOUT)
    ) {
        //first night only
        return {
            background:
                "linear-gradient(-45deg, rgba(105, 1, 1, 1) 0%, rgba(105, 1, 1, 1) 49%, rgba(255, 255, 255, 1) 49%, rgba(255, 255, 255, 1) 100%)",
            color: "#000",
            isAnAvailableDay: availabilityType === "preAvailability",
            date: availability?.date,
        };
    } else if (
        (turnover === TURNOVERS.ONLY_CHECKOUT || turnover === TURNOVERS.NO_CHECKIN_NO_CHECKOUT) &&
        (nextTurnover === TURNOVERS.ONLY_CHECKIN || nextTurnover === TURNOVERS.NO_CHECKIN_NO_CHECKOUT)
    ) {
        //no checkin and checkout
        return {
            background: "#690101",
            color: "#FFF",
            isAnAvailableDay: false,
            date: availability?.date,
        };
    } else if (
        (turnover === TURNOVERS.ONLY_CHECKOUT || turnover === TURNOVERS.NO_CHECKIN_NO_CHECKOUT) &&
        (nextTurnover === TURNOVERS.ONLY_CHECKOUT || nextTurnover === TURNOVERS.ALLOW_CHECKIN_AND_CHECKOUT)
    ) {
        //last night only
        return {
            background:
                "linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 49%, rgba(105, 1, 1, 1) 49%, rgba(105, 1, 1, 1) 100%)",
            color: "#000",
            isAnAvailableDay: availabilityType === "postAvailability",
            date: availability?.date,
        };
    } else {
        return {...calendarDayInfoObject};
    }
}
