import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {FrontendConfiguration} from "@common/configuration";
import {ImplicitFlow, ThemeProvider, JwtValidator} from "@vacasa/react-components-lib";
import DefaultTheme from "@vacasa/react-components-lib/src/themes/default";
import AddNight from "./AddNight";
import {Provider} from "react-redux";
import {store} from "./store/store";
import "./index.scss";
import {datadogLogs} from "@datadog/browser-logs";

Sentry.init({
  dsn: FrontendConfiguration.getSentryDsn(),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.01,
});

const adminConfig = FrontendConfiguration.adminConfig();
const params = new URLSearchParams(window.location.search);
let token: string = params.get("token");
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// if loaded, we remove the reference to force refreshed cache
sessionStorage.removeItem("cache-force-refreshed");

const environment = FrontendConfiguration.environment();

environment !== "local" &&
  datadogLogs.init({
    clientToken: FrontendConfiguration.getDataDogToken(),
    site: FrontendConfiguration.dataDogUrl(),
    forwardErrorsToLogs: true,
    env: FrontendConfiguration.datadogGlobalContext().env,
    sampleRate: 100,
  });
datadogLogs.setLoggerGlobalContext(FrontendConfiguration.datadogCustomGlobalContext(FrontendConfiguration.DD_RESERVATION_MANAGEMENT_UI));

const App = () => (
  <ImplicitFlow {...FrontendConfiguration.implicitFlowConfig()} followRedirect={true}>
    <Provider store={store}>
      <AddNight />
    </Provider>
  </ImplicitFlow>
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={DefaultTheme}>
      {environment === "local" ? (
        <App />
      ) : (
        <JwtValidator jwt={token} base64Key={adminConfig.publicKey} storageName={FrontendConfiguration.tokenStorageName}>
          <App />
        </JwtValidator>
      )}
    </ThemeProvider>
  </React.StrictMode>
);
