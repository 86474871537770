import {configureStore} from "@reduxjs/toolkit";
import addNightReducer from "./addNightSlice";
import fetcherReducer from "./fetcherSlice";
import {useDispatch} from "react-redux";

export const store = configureStore({
    reducer: {
        addNight: addNightReducer,
        fetcher: fetcherReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
