import React, {FC} from "react";
import styles from "./FinancesColumn.module.scss";
import cx from "classnames";

export interface FinancesColumnProps {
  rows: Array<{
    content: JSX.Element;
    title?: string;
    contentHighlighted?: boolean;
  }>;
  header?: string;
  showTitles?: boolean;
  wideBox?: boolean;
  leftMarginContent?: boolean;
  rightMarginContent?: boolean;
}

export const FinancesColumn: FC<FinancesColumnProps> = ({
  showTitles = true,
  leftMarginContent = false,
  rightMarginContent = false,
  wideBox = false,
  ...rest
}) => {
  const {header, rows} = rest;

  return (
    <div>
      {header && (
        <div className={showTitles ? styles.headerRowWithTitles : ""}>
          {showTitles && <div className={styles.headerBox} />}
          <div className={styles.headerBox}>
            <span className={styles.header}>{header}</span>
          </div>
        </div>
      )}
      <div className={styles.divSeparator} />
      <div>
        {rows.map((row, index) => (
          <div
            key={index}
            className={cx(styles.innerTable, !showTitles ? styles.hideTitles : wideBox ? styles.showTitlesWideBox : styles.showTitles)}
          >
            {showTitles && <div className={styles.rowTitle}>{row.title}</div>}
            <div
              className={cx(
                row.contentHighlighted ? styles.rowContentHighlighted : styles.rowContent,
                leftMarginContent ? styles.leftMarginContent : "",
                rightMarginContent ? styles.rightMarginContent : ""
              )}
            >
              {row.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
