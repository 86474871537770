import React, {FC} from "react";
import localStyles from "./Alerts.module.scss";
import {Button, Modal} from "@vacasa/react-components-lib";
import {setPreviewError, setSaveError} from "../../store/addNightSlice";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {ModalImage, AlertComponent} from "../index";
import isNil from "lodash/isNil";
import image from "../../assets/images/early_check_in.png";
import {LegacyUrlGenerator} from "@common/utils";
import {useParams} from "react-router-dom";
import styles from "../add-night-header/AddNightHeader.module.scss";
import {REPORTS_SLACK_CHANNEL} from "../../constants";
import isEmpty from "lodash/isEmpty";

interface AlertsProps {}

export const Alerts: FC<AlertsProps> = () => {
  const dispatch = useAppDispatch();
  const {id} = useParams<{id: string}>();

  const finances = useSelector((state: RootState) => state.addNight.finances);
  const saved = useSelector((state: RootState) => state.addNight.persist.success);
  const saveError = useSelector((state: RootState) => state.addNight.persist.error);
  const previewError = useSelector((state: RootState) => state.addNight.preview.error);
  const previewErrorDetails: string[] = useSelector((state: RootState) => state.addNight.preview.errorDetailsList);
  const availabilityError = useSelector((state: RootState) => state.addNight.availability.error);
  const originalFinancesNotFound = isNil(finances?.original);

  function redirectToURL(url: string) {
    window.top.location.href = url;
  }

  const AdjustmentSuccess = () => {
    return (
      <Modal canExit={false} size="small" showModal={saved} setShowModal={() => {}}>
        <div className={localStyles.modal}>
          <div className={localStyles.content}>
            <img src={image} className={localStyles.modalImage} alt="modalImage" />
            <h3 className={localStyles.titleMessage}>Success</h3>
            <p className={localStyles.descriptionMessage}>Nights added successfully.</p>
            <Button variant="primary" onClick={() => redirectToURL(LegacyUrlGenerator.toReservation(id))}>
              Go to reservation
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const AdjustmentError = () => {
    return (
      <Modal setShowModal={(e) => dispatch(setSaveError(e))} showModal={saveError} size="small" canExit={true}>
        <div className={localStyles.modal}>
          <div className={localStyles.content}>
            <ModalImage image={image} />
            <h3 className={localStyles.titleMessage}>Something Went Wrong...</h3>
            <p className={localStyles.descriptionMessage}>
              Please try refreshing a couple of times, as this is usually a connectivity issue. Also check if this reservation has it's finances
              unlocked.
            </p>
            <Button variant="primary" onClick={() => redirectToURL(LegacyUrlGenerator.toReservation(id))}>
              Go to reservation
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const AdjustmentFetchError = () => {
    const errorDetail = !isEmpty(previewErrorDetails) ? (
      <>
        Details:
        <br />
        {previewErrorDetails.map((e, index) => (
          <span key={index}>
            {e}
            <br />
          </span>
        ))}
      </>
    ) : (
      ""
    );

    const errorMsg: JSX.Element = (
      <>
        Preview Finances are not available for this dates cause it's not Bookable.
        <br />
        {errorDetail}
        <br />
        If this is an unexpected error please report to{" "}
        <a href={REPORTS_SLACK_CHANNEL} className={styles.noTextDecoration} target="_blank" rel="noopener noreferrer">
          #tmp-add-night-ibp
        </a>
      </>
    );
    return (
      <div className={localStyles.alertContainer}>
        <AlertComponent text={errorMsg} className={"error"} iconSize="25" setShowAlert={() => dispatch(setPreviewError(false))} />
      </div>
    );
  };

  const AvailabilityFetchError = () => {
    return (
      <Modal setShowModal={() => availabilityError} showModal={availabilityError} size="small" canExit={false}>
        <div className={localStyles.modal}>
          <div className={localStyles.content}>
            <ModalImage image={image} />
            <h3 className={localStyles.titleMessage}>Something Went Wrong...</h3>
            <p className={localStyles.descriptionMessage}>It is not possible to add nights to this reservation due to non-availability.</p>
            <div className={localStyles.buttonGroup}>
              <Button variant="primary" onClick={() => redirectToURL(LegacyUrlGenerator.toReservation(id))}>
                Go to reservation
              </Button>
              <Button variant="primary" onClick={() => redirectToURL(LegacyUrlGenerator.toAddANight(id))}>
                Go to legacy page
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const FinancesNotFound = () => {
    return (
      <Modal setShowModal={() => originalFinancesNotFound} showModal={originalFinancesNotFound} size="small" canExit={false}>
        <div className={localStyles.modal}>
          <div className={localStyles.content}>
            <ModalImage image={image} />
            <h3 className={localStyles.titleMessage}>Something Went Wrong...</h3>
            <p className={localStyles.descriptionMessage}>
              We couldn't find Finances for this Reservation, things you can try:
              <br />
              1. Unlock Finances & Retry
              <br />
              2. If 1. doesn't work, you can try the legacy Add a Night page.
            </p>

            <div className={localStyles.buttonGroup}>
              <Button variant="primary" onClick={() => redirectToURL(LegacyUrlGenerator.toReservation(id))}>
                Go to reservation
              </Button>
              <Button variant="primary" onClick={() => redirectToURL(LegacyUrlGenerator.toAddANight(id))}>
                Go to legacy page
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      {saved && <AdjustmentSuccess />}
      {saveError && <AdjustmentError />}
      {previewError && <AdjustmentFetchError />}
      {originalFinancesNotFound && <FinancesNotFound />}
      {availabilityError && <AvailabilityFetchError />}
    </div>
  );
};
