import styles from "./FinanceUtils.module.scss";
import isNil from "lodash/isNil";
import find from "lodash/find";
import cx from "classnames";
import React from "react";
import {ReservationDataType} from "@common/typing";

/**
 * Transform number to float 2 decimals fixed
 *
 * @param n
 */
export function floatFixed(n: number) {
  return parseFloat(Number(n).toFixed(2));
}

export type amountInnerType = {
  amount: number | null | "n/a";
  wrapper?: "+/-" | "(+/-)" | "=";
  bold?: boolean;
  highlighted?: boolean;
};

export function getAmountWrapped(amountData: amountInnerType) {
  if (amountData.amount === "n/a") return amountData.wrapper === "(+/-)" ? "(n/a)" : "n/a";
  if (!isNil(amountData.amount)) {
    switch (amountData.wrapper) {
      case "=":
        return `=$${Math.abs(amountData.amount).toFixed(2)}`;
      case "+/-":
        return `${amountData.amount >= 0 ? "+" : "-"}$${Math.abs(amountData.amount).toFixed(2)}`;
      case "(+/-)":
        return `(${amountData.amount >= 0 ? "+" : "-"}$${Math.abs(amountData.amount).toFixed(2)})`;
      default:
        return `$${Math.abs(amountData.amount).toFixed(2)}`;
    }
  }
}

export function amountFinancesContent(primaryAmount: amountInnerType, secondaryAmount?: amountInnerType) {
  return (
    <div className={styles.containerAmounts}>
      <div>
        {primaryAmount &&
          (primaryAmount?.highlighted ? (
            <span
              className={cx(
                styles.financesInnerTableText,
                primaryAmount?.bold === true ? styles.bold : "",
                !isNil(primaryAmount?.amount) && primaryAmount?.amount > 0
                  ? styles.negativeValue
                  : !isNil(primaryAmount?.amount) && primaryAmount?.amount < 0
                  ? styles.positiveValue
                  : ""
              )}
            >
              {getAmountWrapped(primaryAmount)}
            </span>
          ) : (
            <span className={cx(styles.financesInnerTableText, primaryAmount?.bold === true ? styles.bold : "")}>
              {getAmountWrapped(primaryAmount)}
            </span>
          ))}
      </div>
      {secondaryAmount && (
        <div className={cx(styles.financesInnerTableTextAlt, secondaryAmount?.bold === true ? styles.bold : "")}>
          {secondaryAmount?.highlighted ? (
            <span
              className={
                !isNil(secondaryAmount?.amount) && secondaryAmount?.amount > 0
                  ? styles.negativeValue
                  : !isNil(secondaryAmount?.amount) && secondaryAmount?.amount < 0
                  ? styles.positiveValue
                  : ""
              }
            >
              {getAmountWrapped(secondaryAmount)}
            </span>
          ) : (
            getAmountWrapped(secondaryAmount)
          )}
        </div>
      )}
    </div>
  );
}

export function getTotalRent(reservation: ReservationDataType) {
  const finances = find(reservation.included, {type: "reservation_finance"});
  return finances?.attributes?.rent?.reduce((acc, item) => {
    return acc + Number(item.rent);
  }, 0);
}

export function getTotalFees(reservation: ReservationDataType) {
  const finances = find(reservation.included, {type: "reservation_finance"});
  return finances?.attributes?.fees?.reduce((acc, item) => {
    return acc + Number(item.amount);
  }, 0);
}

export function getTotalTaxes(reservation: ReservationDataType) {
  const finances = find(reservation.included, {type: "reservation_finance"});
  return finances?.attributes?.taxes?.reduce((acc, item) => {
    return acc + Number(item.amount);
  }, 0);
}
