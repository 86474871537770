import React from "react";
import "./index.scss";
import {EditContainer, Dates} from "./components";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";

const AddNight = (): JSX.Element => {
  return (
    <div className="addNight">
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/undefined" />} />

          {/** Main flow container **/}
          <Route path="/:id" element={<EditContainer />}>
            <Route path={""} element={<Dates />} />
          </Route>

          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AddNight;
