// This value establish the days mount limit to add before first night or after last night
export const AVAILABILITY_LIMIT_DAYS_TO_ADD = 7;

export const REPORTS_SLACK_CHANNEL: string = "https://vacasa.slack.com/archives/C053V0A378W";

export const OVERRIDE_UTC_RULE: string = "check-out-date";

export const COMMON_DATE_FORMAT = "YYYY-MM-DD";

export enum TURNOVERS {
    NO_CHECKIN_NO_CHECKOUT = 0,
    ALLOW_CHECKIN_AND_CHECKOUT = 1,
    ONLY_CHECKOUT = 2,
    ONLY_CHECKIN = 3,
}
