import React, {FC} from "react";
import styles from "./AddNightHeader.module.scss";
import {FrontendConfiguration} from "@common/configuration";
import {Col, Row} from "antd";
import {RootState} from "../../store/store";
import {useSelector} from "react-redux";
import {BackSection} from "../index";

import {dateToFormat, dayjs} from "../../utils";

const headerDateFormat = "dddd, MMMM Do, YYYY";

interface AddNightHeaderProps {}

export const AddNightHeader: FC<AddNightHeaderProps> = () => {
  const reservation = useSelector((state: RootState) => state.fetcher.reservation);
  const legacyReservationId = reservation?.data?.attributes?.legacy_reservation_id;
  const created = dateToFormat(reservation?.data?.attributes?.created_at, headerDateFormat);
  const firstNight = dateToFormat(reservation?.data?.attributes?.first_night, headerDateFormat);
  const lastNight = dateToFormat(reservation?.data?.attributes?.last_night, headerDateFormat);
  const checkOut = dayjs.tz(reservation?.data?.attributes?.last_night).add(1, "days").format(headerDateFormat);
  const legacyUnitId = useSelector((state: RootState) => state.fetcher.unit?.attributes?.legacy_unit_id);
  const unitCode = useSelector((state: RootState) => state.fetcher.unit?.attributes?.unit_code);

  return (
    <div className={styles.addNightHeader}>
      {/** Back section && Title **/}
      <div>
        <BackSection />
        <Row>
          <Col span={23}>
            <h1>
              <span className={styles.title}>Add Night(s)</span>
            </h1>
          </Col>
        </Row>
      </div>

      {/** Reservation Tags **/}
      <div className={styles.reservationHeader}>
        <div className={styles.reservationHeaderSubTitle}>Res ID</div>
        <div className={styles.reservationHeaderText}>
          <a
            href={FrontendConfiguration.getAdminUrl() + `/admin/dashboard/reservation/${legacyReservationId}`}
            className={styles.noTextDecoration}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={styles.reservationHeaderLink}>{legacyReservationId}</span>
          </a>
        </div>

        <div className={styles.reservationHeaderSubTitle}>Unit Code</div>
        <div className={styles.reservationHeaderText}>
          <a
            href={FrontendConfiguration.getAdminUrl() + `/admin/dashboard/units/index?UnitID=${legacyUnitId}`}
            className={styles.noTextDecoration}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={styles.reservationHeaderLink}>{unitCode}</span>
          </a>
        </div>

        <div className={styles.reservationHeaderSubTitle}>Created</div>
        <div className={styles.reservationHeaderText}>{created}</div>

        <div className={styles.reservationHeaderSubTitle}>First Night</div>
        <div className={styles.reservationHeaderText}>{firstNight}</div>

        <div className={styles.reservationHeaderSubTitle}>Last Night</div>
        <div className={styles.reservationHeaderText}>{lastNight}</div>

        <div className={styles.reservationHeaderSubTitle}>Check out</div>
        <div className={styles.reservationHeaderText}>{checkOut}</div>
      </div>
    </div>
  );
};
