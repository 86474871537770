import {createSlice, createAsyncThunk, isAnyOf} from "@reduxjs/toolkit";
import {fetchReservation, fetchAvailability} from "./fetcherSlice";
import {floatFixed, getTotalRent, getTotalFees, getTotalTaxes} from "../components";
import {getCurrentUser} from "../utils";
import {ReservationImplicitService, EventService} from "@common/services";
import {FrontendConfiguration} from "@common/configuration";
import {AddNightPreviewPayload, AddNightPayload, AddNightPreviewResponse, AvailabilityAttributesType, DataType} from "@common/typing";
import {EventSources, EventTypes} from "@common/utils";
import {datadogLogs} from "@datadog/browser-logs";
import find from "lodash/find";

import {extractErrorMsgList, toDateString} from "../utils";
import {OVERRIDE_UTC_RULE} from "../constants";

// Service singletons
const reservationService = ReservationImplicitService.getInstance();

export const save = createAsyncThunk("addNight/save", async (_, {getState}) => {
    const state = getState() as any;
    const {email: userEmail} = getCurrentUser();
    const client = FrontendConfiguration.getVacasaClient();
    const payload: AddNightPayload = {
        data: {
            type: "add_night",
            attributes: {
                client: client,
                adjustment_preview_id: state.addNight.preview.id,
                adjusted_by_email: userEmail,
            },
        },
    };
    try {
        await reservationService.addNight(state.fetcher.reservation.data.id, payload);

        EventService.dispatch(datadogLogs, {
            title: "Add Night Persist Success",
            message: `Add Night Persist Success - ResID: ${state.fetcher.reservation.data.attributes.legacy_reservation_id}`,
            type: EventTypes.ADD_NIGHT_PERSIST,
            source: EventSources.UI,
            level: EventService.INFO_LEVEL,
            data: {legacy_reservation_id: state.fetcher.reservation.data.attributes.legacy_reservation_id, payload},
        });

        const negativeDifferences = getNegativeDifferences(state.addNight.finances.original, state.addNight.finances.preview);
        if (Object.values(negativeDifferences).some(Boolean)) {
            // If exists negative difference in some item, report to logs
            const preview = state.addNight?.logData?.preview;
            EventService.dispatch(datadogLogs, {
                title: "Add Night Persist with negative difference",
                message: `Add Night Persist with negative difference - ResID: ${state.fetcher.reservation.data.attributes.legacy_reservation_id}`,
                type: EventTypes.ADD_NIGHT_PERSIST_WITH_NEGATIVE_DIFFERENCE,
                source: EventSources.UI,
                level: EventService.WARNING_LEVEL,
                data: {legacy_reservation_id: state.fetcher.reservation.data.attributes.legacy_reservation_id, negativeDifferences, payload, preview},
            });
        }
    } catch (e) {
        EventService.dispatch(datadogLogs, {
            title: "Add Night Persist Fail",
            message: `Add Night Persist Fail - ResID: ${state.fetcher.reservation.data.attributes.legacy_reservation_id} Reservation UUID: ${state.fetcher.reservation.data.id}`,
            type: EventTypes.ADD_NIGHT_PERSIST_FAIL,
            source: EventSources.UI,
            level: EventService.ERROR_LEVEL,
            data: {legacy_reservation_id: state.fetcher.reservation.data.attributes.legacy_reservation_id, payload, response: e},
        });
        throw e;
    }
});

export const reset = createAsyncThunk("addNight/reset", async (_, {getState, dispatch}) => {
    const state = getState() as any;
    dispatch(setLastNight(toDateString(state.fetcher?.reservation?.data?.attributes?.last_night)));
    dispatch(setFirstNight(toDateString(state.fetcher?.reservation?.data?.attributes?.first_night)));
    dispatch(setPreviewData({id: null, preview: null, logPreview: null}));
});

export const getAdjustmentPreview = createAsyncThunk(
    "addNight/getAdjustmentPreview",
    async (data: {firstNight?: string; lastNight?: string}, {getState, dispatch, rejectWithValue}) => {
        const state = getState() as any;

        const {email: userEmail} = getCurrentUser();

        const firstNightToSend = data.firstNight ? data.firstNight : state.addNight?.firstNight;
        const lastNightToSend = data.lastNight ? data.lastNight : state.addNight?.lastNight;

        if (
            firstNightToSend === state.fetcher.reservation.data.attributes.first_night &&
            lastNightToSend === state.fetcher.reservation.data.attributes.last_night
        ) {
            dispatch(setFirstNight(toDateString(firstNightToSend)));
            dispatch(setLastNight(toDateString(lastNightToSend)));
            return {
                id: null,
                preview: null,
                logData: {
                    preview: null,
                },
            };
        }

        const payload: AddNightPreviewPayload = {
            data: {
                type: "adjustment_preview",
                attributes: {
                    first_night: firstNightToSend,
                    last_night: lastNightToSend,
                    adjusted_by_email: userEmail,
                    ignore_rules: [OVERRIDE_UTC_RULE],
                },
            },
        };
        try {
            const preview = await reservationService.getAddNightPreview(state.fetcher.reservation.data.id, payload);
            const previewFinances = preview.data?.attributes?.preview_finances;

            EventService.dispatch(datadogLogs, {
                title: "Add Night Preview Success",
                message: `Add Night Preview Success - ResID: ${state.fetcher.reservation.data.attributes.legacy_reservation_id}`,
                type: EventTypes.ADD_NIGHT_PREVIEW,
                source: EventSources.UI,
                level: EventService.INFO_LEVEL,
                data: {legacy_reservation_id: state.fetcher.reservation.data.attributes.legacy_reservation_id, payload, preview},
            });

            const response = {
                id: preview.data?.id,
                preview: {
                    rent: floatFixed(
                        previewFinances?.rent?.reduce((acc, item) => {
                            return acc + Number(item.rent);
                        }, 0)
                    ),
                    fees: floatFixed(
                        previewFinances?.fees.reduce((acc, item) => {
                            return acc + Number(item.amount);
                        }, 0)
                    ),
                    taxes: floatFixed(
                        previewFinances?.taxes.reduce((acc, item) => {
                            return acc + Number(item.amount);
                        }, 0)
                    ),
                    total: floatFixed(Number(previewFinances?.total)),
                },
                logData: {preview},
            };

            // Check negative differences in finance items
            const negativeDifferences = getNegativeDifferences(state.addNight.finances.original, response.preview);
            if (Object.values(negativeDifferences).some(Boolean)) {
                // If exists negative difference in some item, report to logs and slack
                EventService.dispatch(datadogLogs, {
                    title: "Add Night Preview with negative difference",
                    message: `Add Night Preview with negative difference - ResID: ${state.fetcher.reservation.data.attributes.legacy_reservation_id}`,
                    type: EventTypes.ADD_NIGHT_PREVIEW_WITH_NEGATIVE_DIFFERENCE,
                    source: EventSources.UI,
                    level: EventService.WARNING_LEVEL,
                    data: {
                        legacy_reservation_id: state.fetcher.reservation.data.attributes.legacy_reservation_id,
                        negativeDifferences,
                        payload,
                        preview,
                    },
                });
            }

            if (data.firstNight) dispatch(setFirstNight(toDateString(data.firstNight)));
            if (data.lastNight) dispatch(setLastNight(toDateString(data.lastNight)));

            return response;
        } catch (e) {
            EventService.dispatch(datadogLogs, {
                title: "Add Night Preview Fail",
                message: `Add Night Preview Fail - ResID: ${state.fetcher.reservation.data.attributes.legacy_reservation_id} Reservation UUID: ${state.fetcher.reservation.data.id}`,
                type: EventTypes.ADD_NIGHT_PREVIEW_FAIL,
                source: EventSources.UI,
                level: EventService.ERROR_LEVEL,
                data: {legacy_reservation_id: state.fetcher.reservation.data.attributes.legacy_reservation_id, payload, response: e},
            });
            return rejectWithValue(extractErrorMsgList(e));
        }
    }
);

function getNegativeDifferences(
    original: AddNightFinanceItem,
    preview: AddNightFinanceItem
): {
    rent: boolean;
    fees: boolean;
    taxes: boolean;
    total: boolean;
} {
    return {
        rent: original.rent > preview.rent,
        fees: original.fees > preview.fees,
        taxes: original.taxes > preview.taxes,
        total: original.total > preview.total,
    };
}

type AddNightFinanceItem = {
    rent: number;
    fees: number;
    taxes: number;
    total: number;
};

export interface AddNightState {
    firstNight: string;
    lastNight: string;
    availability: {
        preLimit: null | string;
        postLimit: null | string;
        error: boolean;
        preAvailabilities: DataType<AvailabilityAttributesType>[] | null;
        postAvailabilities: DataType<AvailabilityAttributesType>[] | null;
    };
    finances: {
        original: AddNightFinanceItem | null;
        preview: AddNightFinanceItem | null;
    };
    preview: {
        id: null | string;
        loading: boolean;
        error: boolean;
        errorDetailsList: string[];
    };
    persist: {
        loading: boolean;
        success: boolean;
        error: boolean;
    };
    reset: boolean;
    logData: {
        preview: AddNightPreviewResponse | null;
    };
}

const initialState: AddNightState = {
    firstNight: "",
    lastNight: "",
    availability: {
        preLimit: null,
        postLimit: null,
        error: false,
        preAvailabilities: null,
        postAvailabilities: null,
    },
    finances: {
        original: null,
        preview: null,
    },
    preview: {
        id: null,
        loading: false,
        error: false,
        errorDetailsList: [],
    },
    persist: {
        loading: false,
        success: false,
        error: false,
    },
    reset: false,
    logData: {
        preview: null,
    },
};

const addNightSlice = createSlice({
    name: "addNight",
    initialState,
    reducers: {
        setFirstNight: (state, {payload}) => {
            state.firstNight = payload;
        },
        setLastNight: (state, {payload}) => {
            state.lastNight = payload;
        },
        setReset: (state, {payload}) => {
            state.reset = payload;
        },
        setPreviewData: (state, {payload}) => {
            state.preview.id = payload.id;
            state.finances.preview = payload.preview;
            state.logData.preview = payload.logPreview;
        },
        setPreviewError: (state, {payload}) => {
            state.preview.error = payload;
        },
        setSaveError: (state, {payload}) => {
            state.persist.error = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReservation.fulfilled, (state, {payload}) => {
                state.firstNight = toDateString(payload.data.attributes.first_night);
                state.lastNight = toDateString(payload.data.attributes.last_night);

                let rent, fees, taxes;
                const finances = find(payload.included, {type: "reservation_finance"});
                state.finances.original = finances
                    ? {
                          rent: (rent = floatFixed(getTotalRent(payload))),
                          fees: (fees = floatFixed(getTotalFees(payload))),
                          taxes: (taxes = floatFixed(getTotalTaxes(payload))),
                          total: floatFixed(rent + fees + taxes),
                      }
                    : null;
            })
            .addCase(save.pending, (state) => {
                state.persist.loading = true;
            })
            .addCase(save.fulfilled, (state) => {
                state.persist.loading = false;
                state.persist.success = true;
            })
            .addCase(save.rejected, (state, {payload}) => {
                state.persist.error = true;
                state.persist.loading = false;
            })
            .addCase(getAdjustmentPreview.pending, (state) => {
                state.preview.loading = true;
            })
            .addCase(getAdjustmentPreview.fulfilled, (state, {payload}) => {
                state.preview.id = payload?.id;
                state.finances.preview = payload?.preview;
                state.preview.loading = false;
                state.logData.preview = payload?.logData?.preview;
            })
            .addCase(getAdjustmentPreview.rejected, (state, {payload}) => {
                state.preview.error = true;
                state.preview.loading = false;
                state.preview.errorDetailsList = payload as any;
            })
            .addCase(fetchAvailability.fulfilled, (state, {payload}) => {
                state.availability = {
                    preLimit: payload?.preAvailabilityLimit,
                    postLimit: payload?.postAvailabilityLimit,
                    error: false,
                    preAvailabilities: payload?.preAvailabilities?.data ?? [],
                    postAvailabilities: payload?.postAvailabilities?.data ?? [],
                };
            })
            .addCase(fetchAvailability.rejected, (state) => {
                state.availability.error = true;
            })
            .addMatcher(isAnyOf(setFirstNight, setLastNight), (state) => {
                state.reset = true;
            });
    },
});

export const {setFirstNight, setLastNight, setReset, setPreviewData, setPreviewError, setSaveError} = addNightSlice.actions;

export default addNightSlice.reducer;
