import {mapQuotesErrorMessages} from "@common/utils";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

export function extractErrorMsgList(error: any): string[] {
    try {
        if (!isEmpty(error?.data?.errors))
            return error?.data?.errors?.reduce((acc, curr) => {
                let msg;
                switch (true) {
                    case !isNil(curr.detail?.message?.reason):
                        msg = `${curr.detail?.message?.reason}: ${getQuoteError(error, curr.detail?.message?.details?.toString())}`;
                        break;
                    case !isNil(curr.title):
                        msg = `${curr.title}: ${getQuoteError(error, curr.detail?.toString())}`;
                        break;
                    default:
                        break;
                }
                if (!isEmpty(msg)) acc.push(msg);
                return acc;
            }, []);
        return [];
    } catch (e) {
        return [];
    }
}

function getQuoteError(error: any, message: string): string {
    const quoteError = mapQuotesErrorMessages(error);
    return quoteError !== "Unknown Error" ? quoteError : message;
}
