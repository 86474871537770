import React from "react";
import styles from "./BackSection.module.scss";
import {BsArrowLeftShort} from "react-icons/bs";
import {Col, Row} from "antd";
import {LegacyUrlGenerator} from "@common/utils";
import {useParams} from "react-router-dom";

export const BackSection = (props: any) => {
  const {id} = useParams<{id: string}>();

  function redirectToURL(url: string) {
    window.top.location.href = url;
  }

  return (
    <>
      <span className={styles.arrow} onClick={() => redirectToURL(LegacyUrlGenerator.toReservation(id))}>
        <BsArrowLeftShort style={{verticalAlign: "middle"}} size={"1.5rem"} />
        Back
      </span>
      <Row>
        <Col span={12}>
          <h1 className={styles.title}>{props.text}</h1>
        </Col>
      </Row>
    </>
  );
};
