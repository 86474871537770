import * as dayjs from "dayjs";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import {COMMON_DATE_FORMAT} from "../../constants";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

function dateToFormat(date: Date | string | null, formatString: string) {
    return date ? dayjs.tz(date).format(formatString) : null;
}

function toDateTimeString(date: Date | string | null) {
    return dateToFormat(date, "");
}

function toDateString(date: Date | string | null) {
    return dateToFormat(date, COMMON_DATE_FORMAT);
}

export {dayjs, dateToFormat, toDateString, toDateTimeString};
