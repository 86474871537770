import React, {useRef} from "react";
import {ModalCloseButton} from "./modal-close-button/ModalCloseButton";
import {ModalContent} from "./modal-content/ModalContent";
import style from "./Modal.module.scss";

export const Modal = ({showModal, setShowModal}) => {
  //Ref created to manages the behaviour of the modal (click out of the modal closes it)
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(!showModal);
    }
  };

  return (
    showModal && (
      <div className={style.background} ref={modalRef} onClick={closeModal}>
        <div className={style.modalWrapper}>
          <ModalContent setShowModal={setShowModal} />
          <ModalCloseButton setShowModal={setShowModal} />
        </div>
      </div>
    )
  );
};
