import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {AvailabilityAttributesType, DataType, ReservationDataType, Unit, GetListResponse} from "@common/typing";
import {UnitImplicitService, ReservationImplicitService, EventService, AvailabilityImplicitService} from "@common/services";
import {EventSources, EventTypes} from "@common/utils";
import {datadogLogs} from "@datadog/browser-logs";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import {AVAILABILITY_LIMIT_DAYS_TO_ADD, COMMON_DATE_FORMAT} from "../constants";
import {dayjs} from "../utils";

// Service singletons
const unitService = UnitImplicitService.getInstance();
const reservationService = ReservationImplicitService.getInstance();
const availabilityService = AvailabilityImplicitService.getInstance();

export const fetchAll = createAsyncThunk("fetcher/all", async (id: string, {dispatch}) => {
    try {
        const reservation = await dispatch(fetchReservation(id)).unwrap();
        await Promise.all([
            dispatch(fetchUnit(reservation.data?.attributes?.unit_id)),
            dispatch(
                fetchAvailability({
                    unitId: reservation.data?.attributes?.legacy_unit_id.toString(),
                    firstNight: reservation.data?.attributes?.first_night,
                    lastNight: reservation.data?.attributes?.last_night,
                })
            ),
        ]);
    } catch (error) {
        datadogLogs.logger.error(`[ADD-NIGHT] Error fetching data`, {error});
    }
});

export const fetchReservation = createAsyncThunk("fetcher/reservation", async (id: string): Promise<ReservationDataType> => {
    try {
        const reservation = await reservationService.getReservation(id, false);
        return await reservationService.getReservation(reservation?.data[0]?.id, true, "reservation_finance");
    } catch (error) {
        EventService.dispatch(datadogLogs, {
            title: "Add Night Fetch Reservation Fail",
            message: `Reservation Legacy ID: ${id} `,
            type: EventTypes.ADD_NIGHT_FETCH_RESERVATION_FAIL,
            source: EventSources.UI,
            level: EventService.ERROR_LEVEL,
            data: {id, error},
        });
        return {} as any;
    }
});

export const fetchUnit = createAsyncThunk("fetcher/unit", async (uuid: string) => {
    return await unitService.getUnitByID(uuid);
});

export const fetchAvailability = createAsyncThunk(
    "fetcher/availability",
    async (data: {unitId: string; firstNight: string; lastNight: string}, {getState}) => {
        const [preAvailability, postAvailability]: GetListResponse<AvailabilityAttributesType>[] = await Promise.all([
            getPreAvailability(data.unitId, data.firstNight),
            getPostAvailability(data.unitId, data.lastNight),
        ]);

        const preLimit = getConsecutiveAvailabilityLimit(preAvailability?.data, data.firstNight, -1);
        const postLimit = getConsecutiveAvailabilityLimit(postAvailability?.data, data.lastNight, 1);

        if (preLimit === data.firstNight && postLimit === data.lastNight) {
            const state = getState() as any;
            EventService.dispatch(datadogLogs, {
                title: "Add Night Available Nights Fail",
                message: `No nights available to be added. Reservation UUID: ${state.fetcher.reservation.data.id} `,
                type: EventTypes.ADD_NIGHT_NO_NIGHTS_AVAILABLE,
                source: EventSources.UI,
                level: EventService.ERROR_LEVEL,
                data: {uuid: state.fetcher.reservation.data.id},
            });
            throw new Error("No nights available to be add.");
        }

        return {
            preAvailabilityLimit: preLimit,
            postAvailabilityLimit: postLimit,
            preAvailabilities: preAvailability,
            postAvailabilities: postAvailability,
        };
    }
);

async function getPreAvailability(unitId: string, firstNight: string): Promise<GetListResponse<AvailabilityAttributesType> | null> {
    const today = dayjs.tz().format(COMMON_DATE_FORMAT);
    if (today > firstNight) return null;

    const preAvailabilityParams = {
        "filter[date][between]": `${dayjs.tz(firstNight).add(-AVAILABILITY_LIMIT_DAYS_TO_ADD, "days").format(COMMON_DATE_FORMAT)},${firstNight}`,
        sort: "-date",
        "filter[unit_id]": unitId,
        "filter[available]": true,
        "page[size]": AVAILABILITY_LIMIT_DAYS_TO_ADD,
    };
    try {
        return await availabilityService.getAvailability(preAvailabilityParams);
    } catch (error) {
        return null;
    }
}

async function getPostAvailability(unitId: string, lastNight: string): Promise<GetListResponse<AvailabilityAttributesType> | null> {
    const postAvailabilityParams = {
        "filter[date][between]": `${lastNight},${dayjs.tz(lastNight).add(AVAILABILITY_LIMIT_DAYS_TO_ADD, "days").format(COMMON_DATE_FORMAT)}`,
        sort: "date",
        "filter[unit_id]": unitId,
        "filter[available]": true,
        "page[size]": AVAILABILITY_LIMIT_DAYS_TO_ADD,
    };

    try {
        return await availabilityService.getAvailability(postAvailabilityParams);
    } catch (error) {
        datadogLogs.logger.error(`Error getPostAvailability: ${error}`);
        return null;
    }
}

function getConsecutiveAvailabilityLimit(availability: DataType<AvailabilityAttributesType>[], startDate: any, sort: 1 | -1) {
    if (isEmpty(availability)) return startDate;

    let sortedList = sortBy(availability, ["attributes.date"]);
    if (sort === -1) sortedList = sortedList.reverse();

    return sortedList.reduce((acc, curr) => {
        if (curr.attributes.date === dayjs.tz(acc).add(sort, "days").format(COMMON_DATE_FORMAT)) return curr.attributes.date;
        else return acc;
    }, startDate);
}

export interface FetcherState {
    isLoading: boolean;
    reservation: ReservationDataType;
    unit: Unit;
}

const initialState: FetcherState = {
    isLoading: false,
    reservation: null,
    unit: null,
};

const fetcherSlice = createSlice({
    name: "fetcher",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAll.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(fetchReservation.fulfilled, (state, {payload}) => {
                state.reservation = payload as any;
            })
            .addCase(fetchUnit.fulfilled, (state, {payload}) => {
                state.unit = payload as any;
            });
    },
});

export default fetcherSlice.reducer;
