import React from "react";
import styles from "./AlertComponent.module.scss";
import {BsCheckCircle, BsExclamationCircle, BsExclamationTriangle, BsInfoCircle, BsX} from "react-icons/bs";

interface AlertMessageInput {
  text: JSX.Element;
  className: string;
  title?: string;
  iconSize?: string;
  setShowAlert: Function;
}

export const AlertComponent: React.FunctionComponent<AlertMessageInput> = (props) => {
  const {text, title, className, iconSize, setShowAlert} = props;
  let boxIcon;

  const setIcon = () => {
    switch (className) {
      case "error": {
        boxIcon = <BsExclamationCircle size={iconSize || "24px"} />;
        break;
      }
      case "warning": {
        boxIcon = <BsExclamationTriangle size={iconSize || "24px"} />;
        break;
      }
      case "info": {
        boxIcon = <BsInfoCircle size={iconSize || "24px"} />;
        break;
      }
      case "success": {
        boxIcon = <BsCheckCircle size={iconSize || "24px"} />;
        break;
      }
      default:
        boxIcon = <BsCheckCircle size={iconSize || "24px"} />;
        break;
    }
  };

  setIcon();

  return (
    <div className={`${styles.messageBox} ${styles[className]}`}>
      <span className={styles.icon}>{boxIcon}</span>
      <div className={styles.messages}>
        {title && <p className={styles.title}>{title}</p>}
        <p className={styles.text}> {text}</p>
      </div>
      <BsX className={styles.closeIcon} size="30px" onClick={() => setShowAlert((show: any) => !show)} />
    </div>
  );
};
